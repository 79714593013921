// Here you can add other styles
body {
    font-size: small;
}

.preview-container,
.TransformComponent-module_container__3NwNd,
.TransformComponent-module_content__TZU5O {
    width: 100% !important;
}

.company-name:hover {
    text-decoration: 1px #0d6efd underline;
    cursor: pointer;
    color: #0d6efd;
}

.c-sidebar-nav-custom .c-sidebar-nav.ps {
    font-size: 12px;
}

.c-sidebar-nav-custom .c-sidebar-nav-title {
    padding: 0.4rem 1rem;
    margin-top: 0.2rem;
}

.c-sidebar-nav-custom .c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
    padding: 0.5045rem 1rem;
    font-size: 12px;
}

.c-sidebar-nav-custom .c-sidebar-minimizer {
    flex: 0 0 30px;
}

.c-sidebar-nav-custom .c-sidebar-minimizer::before {
    height: 30px;
}

.c-footer {
    flex: 0 0 30px;
}

@media only screen and (max-width: 1366px) and (min-height: 780px) {
    .c-sidebar-nav-custom .c-sidebar-nav-link,
    .c-sidebar-nav-dropdown-toggle {
        padding: 0.4rem 1rem;
        font-size: 10px;
    }
    .c-sidebar-nav-custom .c-sidebar-nav-title {
        padding: 0.3rem 1rem;
        margin-top: 0.2rem;
        font-size: 9px;
    }
    .c-sidebar-nav-custom .c-sidebar-minimizer {
        flex: 0 0 25px;
    }
    .c-sidebar-nav-custom .c-sidebar-minimizer::before {
        height: 25px;
    }
    .c-footer {
        flex: 0 0 25px;
    }
}

@media only screen and (max-width: 1366px) and (max-height: 768px) {
    .c-sidebar-nav-custom .c-sidebar-nav-link,
    .c-sidebar-nav-dropdown-toggle {
        padding: 0.37rem 1rem;
        font-size: 10px;
    }
    .c-sidebar-nav-custom .c-sidebar-nav-title {
        padding: 0.27rem 1rem;
        margin-top: 0.2rem;
        font-size: 9px;
    }
    .c-sidebar-nav-custom .c-sidebar-minimizer {
        flex: 0 0 25px;
    }
    .c-sidebar-nav-custom .c-sidebar-minimizer::before {
        height: 25px;
    }
    .c-footer {
        flex: 0 0 25px;
    }
}

@media only screen and (max-width: 1280px) and (max-height: 720px) {
    .c-sidebar-nav-custom .c-sidebar-nav-link,
    .c-sidebar-nav-dropdown-toggle {
        padding: 0.3045rem 1rem;
        font-size: 9px;
    }
    .c-sidebar-nav-custom .c-sidebar-nav-title {
        padding: 0.25rem 1rem;
        margin-top: 0.1rem;
        font-size: 8px;
    }
    .c-sidebar-nav-custom .c-sidebar-minimizer {
        flex: 0 0 25px;
    }
    .c-sidebar-nav-custom .c-sidebar-minimizer::before {
        height: 25px;
    }
    .c-footer {
        flex: 0 0 25px;
    }
}